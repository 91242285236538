// Generated by Framer (c815cea)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {JSKyICAix: {hover: true}};

const cycleOrder = ["JSKyICAix"];

const serializationHash = "framer-CVBah"

const variantClassNames = {JSKyICAix: "framer-v-wflidu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "JSKyICAix", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "JSKyICAix-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 203.88889429010004, intrinsicWidth: 203.88889429010004, pixelHeight: 367, pixelWidth: 367, src: "https://framerusercontent.com/images/cA3c4bbfJkZb1eSx39UB5f2K4.svg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wflidu", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"JSKyICAix"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"JSKyICAix-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}>{isDisplayed() && (<Image background={{alt: "", fit: "fill", intrinsicHeight: 203.3333387198273, intrinsicWidth: 203.88889429010004, pixelHeight: 366, pixelWidth: 367, src: "https://framerusercontent.com/images/je8ah5uyMteiFiirNKoRVk8Jpk.png"}} className={"framer-8wbhcq"} data-framer-name={"Virgin"} layoutDependency={layoutDependency} layoutId={"eOrq8EWm1"}/>)}</Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CVBah.framer-1oeio3h, .framer-CVBah .framer-1oeio3h { display: block; }", ".framer-CVBah.framer-wflidu { cursor: pointer; height: 330px; overflow: visible; position: relative; width: 330px; }", ".framer-CVBah .framer-8wbhcq { aspect-ratio: 1.0027322404371584 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 329px); left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-CVBah.framer-v-wflidu.hover.framer-wflidu { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 330px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 330
 * @framerIntrinsicWidth 330
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"H_9LqqeU5":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPQEkUSJoA: React.ComponentType<Props> = withCSS(Component, css, "framer-CVBah") as typeof Component;
export default FramerPQEkUSJoA;

FramerPQEkUSJoA.displayName = "virgin";

FramerPQEkUSJoA.defaultProps = {height: 330, width: 330};

addFonts(FramerPQEkUSJoA, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})